@import url('https://fonts.googleapis.com/css2?family=Halyard+Micro&family=Space+Mono&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Halyard Micro', Arial, sans-serif;
  scroll-behavior: smooth;
}

.home {
  text-align: center;
  color: #ffffff; /* White text */
}

.main-content {
  position: relative;
  height: 90vh;
  background: url('../pages/background.webp') no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff; /* White text */
  text-align: center;
}

.main-content .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Dark overlay */
}

.main-content .content {
  position: relative;
  z-index: 1;
}

.main-content .content h1 {
  font-size: 3em;
  margin: 0;
}

.main-content .content p {
  font-size: 1.25em;
  margin: 10px 0;
}

.main-content .content .button-container {
  margin-top: 20px; /* Add margin-top to move the button down */
}

.main-content .btn {
  background-color: #ff5e1a; /* Green button */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.text-container {
  padding: 20px;
  max-width: 650px;
  margin: 0 auto;
  text-align: left;
  font-size: 1em; /* Smaller font size */
  line-height: 1.5;
  color: #000000; /* Black text */
  background-color: #ffffff; /* White background */
  border-radius: 8px; /* Optional: Add some border radius for better look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better look */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    height: 60vh;
  }

  .main-content .content h1 {
    font-size: 2em;
  }

  .main-content .content p {
    font-size: 1em;
  }

  .main-content .btn {
    padding: 8px 20px;
  }
}
