.resources {
  padding: 40px;
  text-align: left;
  color: #000000; /* Black */
  background-color: #dad9d9; /* Light Gray */
  margin-top: 10px; /* Added margin-top to move content down */
}

.resources h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  color: #000000; /* Black */
  text-align: center;
}

.resources h2 {
  font-size: 2em;
  margin-top: 1.5em;
  color: #282828; /* Dark Gray */
}

.resources ul {
  list-style-type: none;
  padding: 0;
}

.resources ul li {
  margin: 10px 0;
}

.resources ul li a {
  color: #ff5e1a; /* Bright Orange */
  text-decoration: none;
  transition: color 0.3s ease;
}

.resources ul li a:hover {
  color: #000000; /* Black */
}
