.footer {
    background-color: #FAF9F6; /* White background */
    color: #000000; /* Black text */
    padding: 20px;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Add shadow at the top */
  }
  
  .contact-info {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-info h3 {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .contact-info p {
    font-size: 1em;
    line-height: 1.5;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer {
      padding: 10px;
    }
  }
  