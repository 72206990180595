@import url('https://fonts.googleapis.com/css2?family=Halyard+Micro&family=Space+Mono&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Halyard Micro', Arial, sans-serif;
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1;
}

.footer {
  background-color: '#282828';
  color: #000000; /* Black text */
  padding: 20px;
  text-align: center;
}

.contact-info {
  max-width: 600px;
  margin: 0 auto;
}

.contact-info h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 1em;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 10px;
  }
}
