@import url('https://fonts.googleapis.com/css2?family=Chido+123&display=swap');

.navbar {
  background-color: rgba(255, 255, 255, 0.45); /* White with some transparency */
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px); /* Adds a blur effect */
  box-sizing: border-box; /* Ensures padding and width are accounted correctly */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-brand {
  font-family: 'Chido 123', sans-serif;
  color: #000000; /* Black */
  font-size: 1.5em;
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap; /* Prevent wrapping */
}

.navbar-right {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.navbar-right li {
  margin-left: 20px; /* Adjusted margin for better spacing */
}

.navbar-right li a {
  color: #000000; /* Black */
  text-decoration: none;
  display: block;
  border-radius: 5px;
  padding: 5px 10px; /* Smaller padding */
  font-size: 0.9em; /* Smaller font size */
  transition: background-color 0.3s ease, transform 0.3s ease;
  white-space: nowrap; /* Prevent wrapping */
}

.navbar-right li a:hover {
  background-color: #ff5e1a; /* Bright Orange */
  transform: scale(1.1);
}

/* Media queries for responsive design */
@media (max-width: 600px) {
  .navbar {
    padding: 5px; /* Reduce padding on smaller screens */
  }

  .navbar-right {
    margin-left: auto; /* Align items to the right */
  }

  .navbar-right li {
    margin-left: 10px; /* Reduce margin between items */
  }

  .navbar-right li a {
    font-size: 0.8em; /* Reduce font size on mobile */
    padding: 3px 8px; /* Reduce padding on mobile */
  }
}
