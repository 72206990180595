@import url('https://fonts.googleapis.com/css2?family=Halyard+Micro&family=Space+Mono&display=swap');

body {
  background-color: #dad9d9; /* Light Gray */
  color: #000000; /* Black */
  font-family: 'Halyard Micro', Arial, sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.info {
  padding: 40px;
  text-align: center;
  animation: fadeIn 1s ease-in;
  margin-top: 50px; /* Added margin-top to move content down */
}

.info-title {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  color: #000000; /* Black */
  font-family: 'Space Mono', monospace;
}

.chart-title {
  font-size: 2em;
  margin-top: 1.5em;
  color: #282828; /* Dark Gray */
  font-family: 'Space Mono', monospace;
}

.info-text {
  font-size: 1.2em;
  color: #282828; /* Dark Gray */
  line-height: 1.5;
  margin-bottom: 1em;
  animation: slideIn 0.5s ease-in-out;
}

.chart-container {
  margin-top: 20px;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.chart-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.statistics-row {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 0 5px;
  flex-wrap: wrap; /* Add flex-wrap to ensure it wraps on smaller screens */
}

.stat-box {
  background-color: #282828; /* Dark Gray */
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  text-align: center;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
}

.stat-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-box h2 {
  font-size: 1.5em;
  margin: 0;
  color: #ff5e1a; /* Orange */
}

.stat-box p {
  font-size: 1em;
  color: #ffffff; /* White */
}

.stat-box sup {
  font-size: 0.8em;
}

#sources {
  text-align: left;
  margin-top: 40px;
  animation: fadeIn 1s ease-in;
}

#sources h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #000000; /* Black */
  font-family: 'Space Mono', monospace;
}

#sources p {
  font-size: 1.2em;
  color: #282828; /* Dark Gray */
  line-height: 1.5;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .statistics-row {
    flex-direction: column;
    align-items: center;
  }

  .stat-box {
    width: 100%;
    margin: 10px 0;
    max-width: 600px; /* Ensure stat boxes are not wider than the contact box */
  }
}
